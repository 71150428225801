import React from 'react';

function NotFoundView() {
  return (
    <div className='ui container left aligned '>
      <h1>404</h1>
      <p id='about-text'>Page not found :(</p>
    </div>
  );
}

export default NotFoundView;
